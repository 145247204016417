import * as B from '@blueprintjs/core';
import 'chartjs-adapter-moment';
import classnames from 'classnames';
import React from 'react';
import {Column, useSortBy, useTable} from 'react-table';

import {ApiFeature} from 'app/modules/Remote/Feature';

import cs from './MultiFeatureAnalysisTable.styl';
import {FeatureStatsApiResponse} from '../hooks/useAggregatedFeatureStats';

// Extends the individual feature stats objects found on FeatureStatsApiResponse
// into a format react-table can handle
type FeatureStatsRowData = {
  id: string;
  name: string;
} & {
  // This will technically only ever be a number, but because we're using an index signature,
  // we need to allow for string values as well to accommodate the id and name fields.
  [date: string]: number | string;
};

/**
 * Component to render a table of feature rows, as well the setters to affect a chart that
 * takes each row as a dataset.
 */
const MultiFeatureAnlaysisTable: React.FunctionComponent<{
  displayFeatureIds: string[];
  allFeatureIds: string[];
  setDisplayFeatureIds: React.Dispatch<React.SetStateAction<string[]>>;
  featureStats: FeatureStatsApiResponse;
  //TODO: remove this once we have a way to get the feature name from the API response
  rawFeatures: ApiFeature[];
  allDates: string[];
  setShowAggregateTrend: React.Dispatch<React.SetStateAction<boolean>>;
  showAggregateTrend: boolean;
}> = ({
  displayFeatureIds,
  allFeatureIds,
  setDisplayFeatureIds,
  featureStats,
  allDates,
  rawFeatures,
  setShowAggregateTrend,
  showAggregateTrend,
}) => {
  const [searchQuery, setSearchQuery] = React.useState('');

  const featureStatsRows = React.useMemo<FeatureStatsRowData[]>(
    () =>
      featureStats
        ? Object.entries(featureStats)
            .map(([featureId, featureStat]) => ({
              ...featureStat,
              id: featureId,
              name:
                rawFeatures.find((f) => f.id.toString() === featureId)?.properties.name ||
                featureId,
            }))
            .filter((row) => row.name.toLowerCase().includes(searchQuery.toLowerCase()))
        : [],
    [featureStats, rawFeatures, searchQuery]
  );

  const columns = React.useMemo<Column<FeatureStatsRowData>[]>(
    () => [
      {
        Header: 'Property Name',
        accessor: 'name',
        Cell: ({row}) => {
          const featureStat = row.original;
          const featureId = featureStat.id.toString();
          const name = featureStat.name.toString();

          return (
            <div className={classnames(cs.propertyNameCell)} key={featureId}>
              {name}
            </div>
          );
        },
      },
      ...allDates.map((date) => ({
        Header: new Date(date).toLocaleDateString(),
        accessor: date,
        Cell: ({cell}) => (
          <div className={cs.tableCell}>{cell.value ? cell.value.toFixed(2) : '-'}</div>
        ),
      })),
    ],
    [allDates]
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<FeatureStatsRowData>(
      {
        columns,
        data: featureStatsRows,
      },
      useSortBy
    );

  return (
    <div className={cs.multiFeatureAnalysisContainer}>
      <div className={cs.controlsContainer}>
        <B.InputGroup
          leftIcon="search"
          placeholder="Filter properties"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          rightElement={<B.Button minimal icon="cross" onClick={() => setSearchQuery('')} />}
        />
        <B.AnchorButton
          text={showAggregateTrend ? 'Hide trend' : 'Show trend'}
          onClick={() => setShowAggregateTrend((prev) => !prev)}
        />
        <B.AnchorButton onClick={() => setDisplayFeatureIds(allFeatureIds)}>
          Show all
        </B.AnchorButton>

        <B.AnchorButton onClick={() => setDisplayFeatureIds([])}>Hide all</B.AnchorButton>
      </div>

      <div className={cs.tableContainer}>
        <table {...getTableProps()} className={cs.table}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.id}
                className={cs.tableHeaderRow}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={column.id}
                    className={cs.tableHeaderCell}
                  >
                    <div className={cs.headerContent}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <B.Icon icon="arrow-down" />
                          ) : (
                            <B.Icon icon="arrow-up" />
                          )
                        ) : (
                          <B.Icon icon="double-caret-vertical" style={{opacity: 0.3}} />
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              const isSelected = displayFeatureIds.includes(row.original.id);

              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={row.id}
                  className={classnames(cs.tableRow, isSelected ? cs.selected : '')}
                  onClick={() => {
                    if (row.original.id) {
                      setDisplayFeatureIds((prev) => {
                        return prev.includes(row.original.id)
                          ? prev.filter((id) => id !== row.original.id)
                          : [...prev, row.original.id];
                      });
                    }
                  }}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} key={cell.column.id} className={cs.tableCell}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MultiFeatureAnlaysisTable;
