import {ChartData} from 'chart.js';
import moment from 'moment';

import {getAreaColor, makePointColorCallback} from 'app/components/AnalyzePolygonChart/SimpleChart';
import {ApiFeature} from 'app/modules/Remote/Feature/types';
import {DataLayerInfo} from 'app/utils/layers';

import {FeatureStatsApiResponse} from '../hooks/useAggregatedFeatureStats';

export type MultiFeatureScatterDataPoint = {x: number; y: number; date: Date; featureId: string};

/**Used for taking a response to the /statistics endpoint, and converting
 * it into one dataset per feature
 */
export const makeMultiFeatureDatasets = (
  featureStats: FeatureStatsApiResponse,
  //TODO: we probably do not want to have full ApiFeatures here, maybe just a dictionary with id
  // and name plus any other feature properties we might need
  features: ApiFeature[],
  selectedFeatureIds: string[]
): ChartData<'line', MultiFeatureScatterDataPoint[]>['datasets'] => {
  return Object.entries(featureStats).map(([featureId, featureStats]) => {
    const lineColor = hashStringToColor(featureId);
    const lineColorWithOpacity = lineColor.replace('rgb', 'rgba').replace(')', ', 0.1)');
    const rawFeature = features.find((f) => f.id.toString() === featureId);
    const isSelected = selectedFeatureIds.includes(featureId);
    return {
      data: Object.entries(featureStats).map(([date, value]) => {
        return {
          x: moment(date).valueOf(),
          y: value,
          featureId: featureId,
          date: new Date(date),
        } as MultiFeatureScatterDataPoint;
      }),
      label: rawFeature?.properties.name || featureId,
      borderColor: isSelected ? lineColor : lineColorWithOpacity,
      backgroundColor: isSelected ? lineColor : lineColorWithOpacity,
      pointBorderColor: isSelected ? lineColor : lineColorWithOpacity,
      pointHoverBackgroundColor: lineColor,
      pointHoverBorderColor: lineColor,
      hoverBorderColor: lineColor,
      fill: false, // To avoid filling the area under the line
    };
  });
};

/**Used for taking a response to the /statistics endpoint, and collapsing
 * it down to a single timeseries averaged across all points.
 */
export const calculateTrendLine = (
  featureStats?: FeatureStatsApiResponse
): {[date: string]: number} => {
  if (!featureStats) {
    return {};
  }
  // Get all unique timestamps across all features
  const allTimestamps = getAllSortedTimestamps(featureStats);

  // Calculate average for each timestamp
  const averageValueByDate: {[date: string]: number} = {};
  allTimestamps.forEach((timestamp) => {
    let sum = 0;
    let count = 0;

    // Sum up all values for this timestamp
    Object.values(featureStats).forEach((timeseriesData) => {
      const value = timeseriesData[timestamp];
      if (value !== undefined) {
        sum += value;
        count++;
      }
    });

    // Calculate and store average
    averageValueByDate[timestamp] = count > 0 ? sum / count : 0;
  });
  return averageValueByDate;
};

export const makeTrendLineChartDatasets = (
  trendLine: {[date: string]: number},
  layer: DataLayerInfo,
  showFeatureTable: boolean
): ChartData<'line', MultiFeatureScatterDataPoint[]>['datasets'] => {
  const dataPoints = Object.entries(trendLine).map(([date, value]) => {
    return {
      x: moment(date).valueOf(),
      y: value,
      featureId: 'average',
      date: new Date(date),
    } as MultiFeatureScatterDataPoint;
  });
  const pointColorCallback = makePointColorCallback(
    layer.gradientStops,
    layer.dataRange,
    'average',
    layer.key
  );

  const averageTrendLineDataset = {
    data: dataPoints,
    label: 'Average',
    ...(!showFeatureTable
      ? {
          borderColor: (context) => pointColorCallback(context.parsed?.y),
          backgroundColor: (context) => pointColorCallback(context.parsed?.y),
          pointBorderColor: (context) => pointColorCallback(context.parsed.y),
          pointHoverBackgroundColor: (context) => pointColorCallback(context.parsed.y),
          pointHoverBorderColor: (context) => pointColorCallback(context.parsed.y),
          fill: {
            target: 'start',
            above: getAreaColor(layer.gradientStops, layer.dataRange, 'average', layer.key),
          },
        }
      : {
          borderColor: 'black',
          backgroundColor: 'black',
          pointBorderColor: 'black',
          pointHoverBackgroundColor: 'black',
          pointHoverBorderColor: 'black',
        }),
  };
  return [averageTrendLineDataset];
};

/**Function to have stable colors for each feature for now. */
function hashStringToColor(str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const r = Math.abs((hash & 0xff0000) >> 16);
  const g = Math.abs((hash & 0x00ff00) >> 8);
  const b = Math.abs(hash & 0x0000ff);
  return `rgb(${r}, ${g}, ${b})`;
}

export const getAllSortedTimestamps = (featureStats?: FeatureStatsApiResponse): string[] => {
  if (!featureStats) {
    return [];
  }
  const allTimestamps = new Set<string>();
  Object.values(featureStats).forEach((timeseriesData) => {
    Object.keys(timeseriesData).forEach((timestamp) => {
      allTimestamps.add(timestamp);
    });
  });
  return Array.from(allTimestamps).sort();
};
