import {
  ActiveElement,
  Chart,
  ChartData,
  ChartEvent,
  PluginOptionsByType,
  ScaleOptionsByType,
} from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import React, {CSSProperties} from 'react';

import {
  DataRange,
  Graph,
  GraphTimeRange,
  GraphWithTimeRange,
} from 'app/components/AnalyzePolygonChart/types';
import {GraphMode} from 'app/components/AnalyzePolygonPopup/AnalyzePolygonPopup';
import {ApiOrganizationAreaUnit} from 'app/modules/Remote/Organization';
import * as colorUtils from 'app/utils/colorUtils';
import {GradientStops} from 'app/utils/gradientStops';
import {
  CHLORIS_10_CARBON_STOCK_CHANGE,
  CHLORIS_30_CARBON_STOCK_CHANGE,
  PLANET_FOREST_CANOPY_COVER,
  PLANET_FOREST_CANOPY_COVER_3M,
} from 'app/utils/layers';
import * as layerUtils from 'app/utils/layerUtils';
import * as mapUtils from 'app/utils/mapUtils';

import {
  ALPHA_25,
  BORDER_COLOR,
  BORDER_WIDTH,
  CURSOR_WIDTH_ACTIVE,
  CURSOR_WIDTH_INACTIVE,
  DataPoint,
  TOOLTIP_TIME_FORMAT,
  X_SCALE_ID,
  formatAreaInRangeUnits,
  formatUnits,
  getGraphTimeRangeWithBuffer,
  makeTooltipFooterCallback,
  makeTooltipLabelCallback,
  useLineChart,
  useYAxisTooltip,
} from './utils';

/**
 * Component to render a line chart with a single dataset and an x-axis ranging
 * from the earliest data point to the latest.
 *
 * Note: the Chart.js types are a bit lacking (e.g., optional props erroneously
 * typed as required) so we have to do a bit of casting.
 */
const SimpleChart: React.FunctionComponent<{
  graph: Graph | GraphWithTimeRange;
  imageRefs: mapUtils.MapImageRefs;
  areaInM2: number;
  areaUnit: ApiOrganizationAreaUnit;
  onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => void;
  onInitialize: (chart: Chart) => void;
  onPanComplete: (chart: Chart) => void;
  onZoomComplete: (chart: Chart) => void;
  devicePixelRatio?: number;
  disablePanZoom?: boolean;
  hideTooltip?: boolean;
  showTitle?: boolean;
  graphStyle?: CSSProperties;
  isExpanded: boolean;
  children: (chart: Chart) => React.ReactNode;
  dataRange: DataRange;
}> = ({
  graph,
  imageRefs,
  areaInM2,
  areaUnit,
  onClick,
  onInitialize,
  onPanComplete,
  onZoomComplete,
  devicePixelRatio,
  disablePanZoom,
  hideTooltip,
  showTitle,
  graphStyle,
  isExpanded,
  children,
  dataRange,
}) => {
  const pointColorCallback = React.useMemo(
    () =>
      makePointColorCallback(
        graph.layerGradientStops,
        graph.layerDataRange,
        graph.graphMode,
        graph.layerKey
      ),
    [graph]
  );
  const dataPoints = React.useMemo<DataPoint[]>(
    () =>
      graph.dataPoints.map(([date, value, meta]) => {
        const adjustedDate = layerUtils.conditionallyAdjustLayerDate(date, graph.layerKey);
        return {
          x: adjustedDate.valueOf(),
          y: value,
          yMin: meta.lowerUncertainty,
          yMax: meta.upperUncertainty,
          date,
          cursor: meta.cursor,
        } as DataPoint;
      }),
    [graph.dataPoints, graph.layerKey]
  );

  /** Calculate x-axis values. */
  const {dates, timeRangeLimits, initialTimeRange} = React.useMemo(() => {
    const dates = dataPoints.map(({date}) => date);

    const timeRangeLimits: GraphTimeRange = getGraphTimeRangeWithBuffer(dates);

    /** Get initial time range from graph prop, if it exists; this should only
     * be the case for graphs saved on notes. */
    const initialTimeRange = graph.graphTimeRange || timeRangeLimits;

    return {dates, timeRangeLimits, initialTimeRange};
  }, [dataPoints, graph.graphTimeRange]);

  /** Create one dataset containing all data points. */
  const datasets = React.useMemo<ChartData<'line'>['datasets']>(() => {
    return [
      {
        data: dataPoints,
        //TODO(eva): this is working but shouldn't be. pointBackgroundColor is not working
        //and backgroundColor/borderColor is applying to the points as well as the line.
        borderColor: (context) => pointColorCallback(context.parsed?.y),
        backgroundColor: (context) => pointColorCallback(context.parsed?.y),
        pointBorderColor: (context) => pointColorCallback(context.parsed.y),
        pointHoverBackgroundColor: (context) => pointColorCallback(context.parsed.y),
        pointHoverBorderColor: (context) => pointColorCallback(context.parsed.y),
        pointHoverRadius: hideTooltip ? 3 : 5,
        borderWidth: BORDER_WIDTH,
        fill: {
          target: 'start',
          above: getAreaColor(
            graph.layerGradientStops,
            graph.layerDataRange,
            graph.graphMode,
            graph.layerKey
          ),
        },
      },
    ];
  }, [dataPoints, graph, hideTooltip, pointColorCallback]);

  /** Display a vertical line for each image reference with a valid cursor. */
  const annotationPluginOptions = React.useMemo<PluginOptionsByType<'line'>['annotation']>(
    () => ({
      drawTime: 'beforeDraw',
      annotations: imageRefs.reduce((annotations, imageRef, index) => {
        const imageRefCursor = imageRef.cursor;
        if (imageRefCursor === null) {
          return annotations;
        }

        const imageRefDate = layerUtils.conditionallyAdjustLayerDate(
          new Date(imageRefCursor),
          imageRef.layerKey
        );
        const imageRefDataPoint = dataPoints.find((d) =>
          moment(d.cursor).isSame(moment(imageRefCursor))
        );
        const isSameLayer = graph.layerKey === imageRef.layerKey;

        return {
          ...annotations,
          [`imageRef-${index}`]: {
            type: 'line',
            scaleID: X_SCALE_ID,
            value: imageRefDate.valueOf(),
            borderColor: isSameLayer ? pointColorCallback(imageRefDataPoint?.y) : BORDER_COLOR,
            borderWidth: isSameLayer ? CURSOR_WIDTH_ACTIVE : CURSOR_WIDTH_INACTIVE,
            borderDash: [4, 2],
          },
        };
      }, {}),
    }),
    [dataPoints, graph.layerKey, imageRefs, pointColorCallback]
  );

  /** Do not display a legend. */
  const legendPluginOptions = React.useMemo<PluginOptionsByType<'line'>['legend']>(
    () =>
      ({
        display: false,
      }) as PluginOptionsByType<'line'>['legend'],
    []
  );

  /** Optionally display a tooltip on point hover. */
  const tooltipPluginOptions = React.useMemo<PluginOptionsByType<'line'>['tooltip']>(
    () =>
      ({
        enabled: !hideTooltip,
        displayColors: false,
        callbacks: {
          label: makeTooltipLabelCallback(graph, areaInM2, areaUnit),
          afterLabel: makeTooltipFooterCallback(graph),
        },
      }) as PluginOptionsByType<'line'>['tooltip'],
    [areaInM2, areaUnit, graph, hideTooltip]
  );

  /** Allow chart to be optionally panned and zoomed. */
  const zoomPluginOptions = React.useMemo<PluginOptionsByType<'line'>['zoom']>(
    () => ({
      limits: {
        x: {
          min: timeRangeLimits[0].getTime(),
          max: timeRangeLimits[1].getTime(),
          minRange: 15 * 24 * 60 * 60 * 1000,
        },
      },
      pan: {
        mode: 'x',
        /** HACK: The chartjs-plugin-zoom plugin does not respond to changes in
         * the pan.enabled option after initialization, but it does respond to
         * changes in pan.onPanStart. */
        enabled: true,
        onPanStart: disablePanZoom ? () => false : undefined,
        onPanComplete: ({chart}) => onPanComplete(chart),
      },
      zoom: {
        mode: 'x',
        pinch: {
          enabled: !disablePanZoom,
        },
        wheel: {
          enabled: !disablePanZoom,
        },
        onZoomComplete: ({chart}) => onZoomComplete(chart),
      },
    }),
    [disablePanZoom, onPanComplete, onZoomComplete, timeRangeLimits]
  );

  /** Configure time scale (i.e., x-axis) settings. */
  const xScaleOptions = React.useMemo<ScaleOptionsByType<'time'>>(
    () =>
      ({
        type: 'time',
        min: initialTimeRange[0].getTime(),
        max: initialTimeRange[1].getTime(),
        time: {
          tooltipFormat: TOOLTIP_TIME_FORMAT,
        },
        ticks: {
          autoSkip: true,
          autoSkipPadding: 10,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          borderDash: [1, 1],
        },
      }) as ScaleOptionsByType<'time'>,
    [initialTimeRange]
  );

  /** Configure linear scale (i.e., y-axis) settings. */
  const yScaleOptions = React.useMemo<ScaleOptionsByType<'linear'>>(
    () =>
      ({
        type: 'linear',
        min: dataRange[0],
        max: dataRange[1] || 1, // If max is 0, set it to 1 to avoid breaking y-axis labels
        ticks: {
          precision: 1,
          maxTicksLimit: 2,
          callback: function (value, _index, _ticks) {
            // the Area in Range y-axis values are percentages of an area that meet a threshold
            // rather than the unit associated with a particular layer, so format it as a percent
            return graph.graphMode === 'area'
              ? formatAreaInRangeUnits(value)
              : formatUnits(value, graph.layerDataUnit);
          },
        },
        grid: {
          borderDash: [1, 1],
          drawBorder: false,
        },
      }) as ScaleOptionsByType<'linear'>,
    [dataRange, graph.graphMode, graph.layerDataUnit]
  );
  const titlePluginOptions = React.useMemo<Partial<PluginOptionsByType<'line'>['title']>>(
    () => ({
      display: !!showTitle,
      text: graph.layerDisplay,
      font: {weight: 'normal'} as PluginOptionsByType<'line'>['title']['font'],
    }),
    [showTitle, graph.layerDisplay]
  );

  const {renderAxisTooltip, hoverYAxisPlugin} = useYAxisTooltip(graph);

  const {chart, canvasRefCallback} = useLineChart({
    datasets,
    labels: dates,
    annotationPluginOptions,
    legendPluginOptions,
    tooltipPluginOptions,
    zoomPluginOptions,
    titlePluginOptions,
    xScaleOptions,
    yScaleOptions,
    plugins: [hoverYAxisPlugin],
    onClick,
    onInitialize,
    devicePixelRatio,
    isExpanded,
  });

  return (
    <React.Fragment>
      <div style={graphStyle}>
        <canvas ref={canvasRefCallback} />
      </div>
      {!!chart && children(chart)}
      {renderAxisTooltip()}
    </React.Fragment>
  );
};

export default SimpleChart;

/**
 * Make the point color callback, which returns an appropriate color for the
 * graph mode from the layer’s color scale.
 */
export function makePointColorCallback(
  layerGradientStops: GradientStops,
  layerDataRange: DataRange,
  graphMode: GraphMode,
  layerKey: string
) {
  const pointColorCallback = (value?: number) => {
    const fixedColor = '#5c7080';
    const colorScale = colorUtils.graphScaleFromGradientStops(layerGradientStops, layerDataRange);

    switch (graphMode) {
      case 'aggregateCarbonSalo':
      case 'aggregateCarbonSpaceIntelligence': {
        if (value == undefined) {
          return BORDER_COLOR;
        } else {
          return colorScale(layerDataRange[1]).hex();
        }
      }
      case 'area':
        return fixedColor;

      case 'average':
      case 'averageMonthly': {
        if (value == undefined) {
          return BORDER_COLOR;
        } else if (
          layerKey === PLANET_FOREST_CANOPY_COVER_3M ||
          layerKey === PLANET_FOREST_CANOPY_COVER ||
          layerKey === CHLORIS_10_CARBON_STOCK_CHANGE ||
          layerKey === CHLORIS_30_CARBON_STOCK_CHANGE
        ) {
          return colorScale(layerDataRange[1]).hex();
        }
        return colorScale(value).hex();
      }

      default:
        return BORDER_COLOR;
    }
  };

  return pointColorCallback;
}

// /**
//  * Get the color of the line connecting the points.
//  */
// function getBorderColor(graph: Graph | GraphWithTimeRange) {
//   switch (graph.graphMode) {
//     case 'aggregateCarbonSalo':
//     case 'aggregateCarbonSpaceIntelligence':
//     case 'area':
//       return makePointColorCallback(graph)();

//     default:
//       return BORDER_COLOR;
//   }
// }

/**
 * Get the color of the area between the x-axis and points.
 */
export function getAreaColor(
  layerGradientStops: GradientStops,
  layerDataRange: DataRange,
  graphMode: GraphMode,
  layerKey: string
): string {
  switch (graphMode) {
    case 'aggregateCarbonSalo':
    case 'aggregateCarbonSpaceIntelligence':
    case 'area':
      return (
        makePointColorCallback(layerGradientStops, layerDataRange, graphMode, layerKey)() + ALPHA_25
      );

    case 'average':
    default:
      return BORDER_COLOR + ALPHA_25;
  }
}
